var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"rounded elevation-3 bg-white",attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('nngp-form',{attrs:{"tags":_vm.tags},on:{"on-submit":function($event){return _vm.onSubmit()},"on-close":function($event){return _vm.onClose()}},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1),_c('h2',[_vm._v("Report NNGP")]),_c('v-row',{attrs:{"justify":"end","align":"end"}},[_c('v-col',{attrs:{"cols":"10","lg":"4"}},[_c('search-only-tags',{attrs:{"tags":_vm.tags},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1),_c('v-col',{staticClass:"mb-1",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.initNNGP()}}},[_vm._v(" Search ")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.activeDialog()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.tag",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCapitalize")(item.tag))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.cost))+" ")]}},{key:"item.ngp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.ngp))+" ")]}},{key:"item.nngp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.nngp))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('gw-icon-hover',{staticClass:"mr-2",attrs:{"icon-name":"mdi-pencil","icon-hover":"mdi-pencil","small":""},on:{"click":function($event){return _vm.editCollection(item)}}}),_c('gw-icon-hover',{staticClass:"sku-table-bin",attrs:{"icon-name":"mdi-delete","icon-hover":"mdi-delete-empty","small":""},on:{"click":function($event){return _vm.removeCollection(item)}}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }