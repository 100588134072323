<template>
  <v-card>
    <validation-observer
      ref="attributeForm"
      tag="form"
      @submit.prevent="save()">
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <gw-autocomplete
                v-model="formData.tag"
                :items="tags"
                :rules="textFieldRules"
                :readonly="modalLoading"
                :return-object="false"
                :height="40"
                label="Tag"
                item-text="name"
                item-value="name"
                outlined />
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col align-self="center">
                  <h3>Costs</h3>
                </v-col>
                <v-spacer />
                <v-col class="text-end">
                  <v-btn
                    color="secondary"
                    @click="addCost()">
                    <v-icon>
                      mdi-plus
                    </v-icon> Add
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-for="(cost, i) in formData.costs"
                :key="`cost-${i}`">
                <v-col cols="10">
                  <gw-text-field
                    v-model.number="formData.costs[i]"
                    :readonly="modalLoading"
                    outlined
                    dense
                    hide-details
                    label="Cost" />
                </v-col>
                <v-col
                  cols="auto"
                  class="text-center">
                  <v-btn
                    color="error"
                    icon
                    @click="deleteCost(i)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeFormModal()">
          Cancel
        </v-btn>
        <v-btn
          :loading="modalLoading"
          color="blue darken-1"
          type="submit"
          text>
          Save
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    tags: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      modalLoading: false,
      textFieldRules: {
        required: true
      }
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addCost () {
      this.formData.costs.push(0)
    },
    deleteCost (index) {
      if (this.formData.costs.length === 1) {
        this.formData.costs.splice(index, 1, 0)
      } else {
        this.formData.costs.splice(index, 1)
      }
    },
    async save () {
      this.modalLoading = true
      const valid = await this.$refs.attributeForm.validate()
      if (valid) {
        this.$emit('on-submit')
      }
      this.modalLoading = false
    },
    closeFormModal () {
      this.$emit('on-close')
    }
  }
}
</script>
