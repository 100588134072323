<template>
  <div class="search-tag-report d-flex flex-column align-center w-full">
    <div class="tags-container mb-1">
      <v-chip
        v-for="(tagSelected, index) in searchTags"
        :key="`tag-selected-chip-${index}`"
        class="ma-1"
        color="teal"
        text-color="white"
        small
        label
        close
        @click:close="unSelectTag(index)">
        {{ tagSelected }}
      </v-chip>
    </div>
    <div class="d-flex align-center w-full">
      <v-autocomplete
        v-model="selectedTags"
        :items="autoCompleteTags"
        :search-input.sync="tagSearch"
        height="40"
        multiple
        filled
        solo
        hide-details
        hide-selected>
        <template v-slot:append>
          <v-icon x-small>
            mdi-tag
          </v-icon>
        </template>
        <template v-slot:selection>
          <span></span>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => ({
        searchTags: []
      })
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tagSearch: ''
    }
  },
  computed: {
    autoCompleteTags () {
      return this.tags.map((tag) => tag.name)
    },
    selectedTags: {
      get () {
        return this.searchTags
      },
      set (val) {
        this.searchTags = val
        this.tagSearch = ''
      }
    },
    searchTags: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag.name === this.searchTags[index])
      if (tagIndex !== -1) {
        this.searchTags.splice(index, 1)
      }
    }
  }

}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.search-tag-report {
  width: 100%;
}
.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.tags-operation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.search-tag-report .v-input__control {
  max-width: unset !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
.search-tag-report .v-input__slot {
  max-width: unset !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
</style>
