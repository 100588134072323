import { render, staticRenderFns } from "./SearchOnlyTags.vue?vue&type=template&id=35e861ea&scoped=true&"
import script from "./SearchOnlyTags.vue?vue&type=script&lang=js&"
export * from "./SearchOnlyTags.vue?vue&type=script&lang=js&"
import style0 from "./SearchOnlyTags.vue?vue&type=style&index=0&id=35e861ea&scoped=true&lang=css&"
import style1 from "./SearchOnlyTags.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e861ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAutocomplete,VChip,VIcon})
