<template>
  <v-container
    class="rounded elevation-3 bg-white"
    fluid>
    <v-dialog
      v-model="dialog"
      max-width="500px">
      <nngp-form
        v-model="formData"
        :tags="tags"
        @on-submit="onSubmit()"
        @on-close="onClose()" />
    </v-dialog>
    <h2>Report NNGP</h2>
    <v-row
      justify="end"
      align="end">
      <v-col
        cols="10"
        lg="4">
        <search-only-tags
          v-model="selectedTags"
          :tags="tags" />
      </v-col>
      <v-col
        cols="auto"
        class="mb-1">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="initNNGP()">
          Search
        </v-btn>
        <v-btn
          color="secondary"
          @click="activeDialog()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          hide-default-footer>
          <template #[`item.tag`]="{ item }">
            {{ item.tag | toCapitalize() }}
          </template>
          <template #[`item.cost`]="{ item }">
            {{ item.cost | showFullPriceFormat() }}
          </template>
          <template #[`item.ngp`]="{ item }">
            {{ item.ngp | showFullPriceFormat() }}
          </template>
          <template #[`item.nngp`]="{ item }">
            {{ item.nngp | showFullPriceFormat() }}
          </template>
          <template #[`item.edit`]="{ item }">
            <gw-icon-hover
              class="mr-2"
              icon-name="mdi-pencil"
              icon-hover="mdi-pencil"
              small
              @click="editCollection(item)" />
            <gw-icon-hover
              class="sku-table-bin"
              icon-name="mdi-delete"
              icon-hover="mdi-delete-empty"
              small
              @click="removeCollection(item)" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import NNGPProvider from '@/resources/NNGPProvider'
import SearchOnlyTags from '@/components/SearchOnlyTags.vue'
import NNGPForm from '../components/NNGPForm.vue'

const ProductAttributeService = new ProductAttributeProvider()
const NNGPService = new NNGPProvider()

export default {
  components: { SearchOnlyTags, nngpForm: NNGPForm },
  data () {
    return {
      loading: false,
      dialog: false,
      headers: [
        {
          text: 'Collection',
          value: 'tag',
          sortable: false
        },
        {
          text: 'Cost',
          value: 'cost',
          sortable: false
        },
        {
          text: 'NGP',
          value: 'ngp',
          sortable: false
        },
        {
          text: 'NNGP',
          value: 'nngp',
          sortable: false
        },
        {
          text: '',
          value: 'edit',
          sortable: false
        }
      ],
      items: [],
      tags: [],
      selectedTags: [],
      formData: {
        id: '',
        tag: '',
        costs: [0]
      },
      isEdited: false
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Collection' : 'New Collection'
    }
  },
  mounted () {
    this.getTags()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    async getTags () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setSnackbar({
          value: true,
          message: `[GET-TAGS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async initNNGP () {
      if (!this.selectedTags.length) {
        return
      }

      try {
        this.loading = true
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING REPORT...'
        })

        const { data } = await NNGPService.getNNGP({
          tags: this.selectedTags
        })

        this.items = data
      } catch (error) {
        console.error('initNNGP', error)
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.setLoading({ active: false })
      }
    },
    activeDialog () {
      this.dialog = true
    },
    async editCollection (item) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING COLLECTION...'
        })

        const { data } = await NNGPService.getNNGPById(item.id)

        this.formData = { ...data }
        this.isEdited = true
        this.dialog = true
      } catch (error) {
        console.error('editCollection', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    removeCollection (item) {
      this.setModal({
        value: true,
        title: 'Remove Collection',
        message: 'Do you want to remove the Collection?',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveCollection(item.id)
      })
    },
    async confirmRemoveCollection (id) {
      try {
        this.loading = true

        const { data } = await NNGPService.deleteNNGP(id)

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Collection removed.',
            type: 'success'
          })

          this.initNNGP()
        } else {
          const error = {
            code: 400,
            message: 'Collection ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        console.error('confirmRemoveCollection', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      let isSuccess = false
      let message = 'created'
      try {
        if (this.isEdited) {
          message = 'updated'
          const { data } = await NNGPService.updateNNGP(this.formData.id, this.formData)

          if (data) {
            isSuccess = true
          }
        } else {
          const { data } = await NNGPService.createNNGP(this.formData)

          if (data) {
            isSuccess = true
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('onSubmit', error)
      } finally {
        if (isSuccess) {
          this.setSnackbar({
            value: true,
            message: `Collection ${message}`,
            type: 'success'
          })
          this.onClose()
          this.initNNGP()
        } else {
          this.setSnackbar({
            value: true,
            message: `Collection ${message} failed.`,
            type: 'error'
          })
        }
      }
    },
    onClose () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.bg-white {
  background-color: white !important;
}
</style>
