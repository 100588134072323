import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class NNGPProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getNNGP (query) {
    this.setHeader(getAuthToken())
    return this.get('report/nngp', query)
  }

  getNNGPById (id) {
    this.setHeader(getAuthToken())
    return this.get(`nngp/${id}`)
  }

  createNNGP (payload) {
    this.setHeader(getAuthToken())
    return this.post('nngp', payload)
  }

  updateNNGP (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`nngp/${id}`, payload)
  }

  deleteNNGP (id) {
    this.setHeader(getAuthToken())
    return this.delete(`nngp/${id}`)
  }
}

export default NNGPProvider
